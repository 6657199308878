import React from 'react';
import MosqueMap from '../components/MosqueMap';

const InfoPage: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto p-4">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        
        <div className="lg:col-span-2 flex flex-col space-y-6">
          
          {/* Welcome Card */}
          <div className="bg-secondary rounded-lg p-4 text-white">
            <h2 className="text-xl sm:text-2xl font-arial-rounded-bold mb-3">Välkommen till Mjölby Moskés Webbtjänst</h2>
            <p className="text-ml sm:text-base mb-3 font-arial-rounded">
              Denna webbtjänst är skapad av Mjölby Moské för att ge mer exakta bönetider baserade på lokala observationer 
              - samma tider som används i moskén. Vi strävar efter att kontinuerligt förbättra och utöka funktionerna 
              för att bättre tjäna vår gemenskap.
            </p>
            <p className="text-ml sm:text-base font-arial-rounded">
              Vårt mål är att göra det enklare för dig att hålla reda på bönetider. 
              Vi uppskattar dina synpunkter och förslag för att göra denna tjänst ännu bättre!
            </p>
          </div>

          {/* About Mosque Card */}
          <div className="bg-secondary rounded-lg p-4 text-white">
            <h2 className="text-xl sm:text-2xl font-arial-rounded-bold mb-3">Om Mjölby Moské</h2>
            <p className="text-ml sm:text-base mb-4 font-arial-rounded">
              Mjölby Moské tjänar som ett centralt samlingsställe för den muslimska gemenskapen i Mjölby och närliggande områden, öppen för alla som är intresserade av att utforska islam, delta i gudstjänster eller engagera sig i vår förening.
            </p>
            <h3 className="text-lg sm:text-xl font-arial-rounded-bold mb-2">Öppettider</h3>
            <p className="text-ml sm:text-base mb-3 font-arial-rounded">
              Moskén är alltid öppen vid bönestunderna.
            </p>
            <h3 className="text-lg sm:text-xl font-arial-rounded-bold mb-2">Besöksadress</h3>
            <p className="text-ml sm:text-base font-arial-rounded">
              Hulje Östergård 1, Mjölby
            </p>
          </div>

        </div>

        {/* Second Column: Mosque Map Card */}
        <MosqueMap />

      </div>
    </div>
  );
};

export default InfoPage;
