import React from 'react';

interface DateSelectorProps {
  selectedDate: Date;
  onDateSelect: (date: Date) => void;
}

const DateSelector: React.FC<DateSelectorProps> = ({ selectedDate, onDateSelect }) => {
  const getWeekDays = (): { date: Date; dayName: string }[] => {
    const days = [];
    const startDate = new Date(selectedDate);
    startDate.setDate(startDate.getDate() - (startDate.getDay() + 6) % 7); // Start from Monday

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + i);
      days.push({
        date: currentDate,
        dayName: currentDate.toLocaleDateString('sv-SE', { weekday: 'short' })
      });
    }

    return days;
  };

  const isCurrentDate = (date: Date): boolean => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  };

  return (
    <div className="flex justify-between mb-4 sm:mb-6 overflow-x-auto">
      {getWeekDays().map((day, index) => (
        <div key={index} className="text-center mr-2 sm:mr-0 flex-shrink-0">
          <div className="text-sm sm:text-base mb-1">{day.dayName}</div>
          <div 
            className={`w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center rounded-full cursor-pointer text-base sm:text-lg relative
              ${day.date.toDateString() === selectedDate.toDateString() ? 'bg-white text-primary' : ''}
              transition-all duration-200`}
            onClick={() => onDateSelect(day.date)}
          >
            {isCurrentDate(day.date) && (
              <div className="absolute inset-0.5 rounded-full border border-white"></div>
            )}
            {day.date.getDate()}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DateSelector;