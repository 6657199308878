import { supabase } from './supabase';
import { PrayerTime, WeeklyPrayerTime } from './types';

export const fetchPrayerTimes = async (startDate: string, endDate: string): Promise<PrayerTime[]> => {
  try {
    const { data, error } = await supabase
      .from('prayer_times')
      .select('*')
      .gte('date', startDate)
      .lte('date', endDate)
      .order('date', { ascending: true });
    
    if (error) {
      throw error;
    }

    return data || [];
  } catch (error) {
    console.error('Error fetching prayer times:', error);
    throw error;
  }
};

export const fetchWeeklyPrayerTimes = async (): Promise<WeeklyPrayerTime | null> => {
  try {
    const today = new Date();
    const todayStr = today.toISOString().split('T')[0];

    const { data, error } = await supabase
      .from('weekly_prayer_times')
      .select('*')
      .lte('week_start', todayStr)
      .gte('week_end', todayStr)
      .single();

    if (error) {
      console.error('Error fetching weekly prayer times:', error);
      return null;
    }

    return data;
  } catch (error) {
    console.error('Error fetching weekly prayer times:', error);
    return null;
  }
};
