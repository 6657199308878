import { PrayerTimesData } from './prayerTimeUtils';
import { endOfMonth } from 'date-fns';

const CACHE_KEY = 'prayerTimes-current';

interface CachedData {
    timestamp: number;
    data: PrayerTimesData;
    month: number; // 0-based month
    year: number;
}

// Retrieve cached prayer times for the current month
export const getCachedPrayerTimes = (currentYear: number, currentMonth: number): PrayerTimesData | null => {
    const cached = localStorage.getItem(CACHE_KEY);
    if (cached) {
        try {
            const parsed: CachedData = JSON.parse(cached);
            const now = new Date();
            const cacheExpiry = endOfMonth(new Date(parsed.year, parsed.month, 1)).getTime();

            // Check if cached data is for the current month and year
            if (parsed.year === currentYear && parsed.month === currentMonth) {
                if (now.getTime() < cacheExpiry) {
                    console.log(`[Cache] Retrieved data from cache for ${parsed.year}-${parsed.month + 1}`, parsed.data);
                    return parsed.data;
                } else {
                    console.log(`[Cache] Cached data for ${parsed.year}-${parsed.month + 1} is expired`);
                    localStorage.removeItem(CACHE_KEY);
                    return null;
                }
            } else {
                console.log(`[Cache] Cached data is for ${parsed.year}-${parsed.month + 1}, which does not match current month ${currentYear}-${currentMonth + 1}`);
                localStorage.removeItem(CACHE_KEY);
                return null;
            }
        } catch (error) {
            console.error('Error parsing cached prayer times:', error);
            localStorage.removeItem(CACHE_KEY);
            return null;
        }
    }
    console.log(`[Cache] No cached data found for ${currentYear}-${currentMonth + 1}`);
    return null;
};

// Cache prayer times for the current month with timestamp and month info
export const setCachedPrayerTimes = (year: number, month: number, data: PrayerTimesData): void => {
    const cachedData: CachedData = {
        timestamp: Date.now(),
        data,
        month,
        year,
    };
    try {
        localStorage.setItem(CACHE_KEY, JSON.stringify(cachedData));
        console.log(`[Cache] Cached data for ${year}-${month + 1}`, data);
    } catch (error) {
        console.error('Error setting cached prayer times:', error);
    }
};

// Clear the current cache (used when fetching new month's data)
export const clearCachedPrayerTimes = (): void => {
    localStorage.removeItem(CACHE_KEY);
    console.log(`[Cache] Cleared cache for previous month`);
};
