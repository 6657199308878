import React from 'react';

const MosqueMap: React.FC = () => {
    const openGoogleMaps = () => {
        window.open('https://goo.gl/maps/Ld5Eo9Ld4Ld5Eo9Ld4', '_blank');
    };

    return (
        <div className="bg-secondary rounded-lg p-4 text-white">
            <h2 className="text-xl sm:text-2xl font-arial-rounded-bold mb-3">Vår Plats</h2>
            <div 
                className="relative cursor-pointer rounded-md overflow-hidden"
                onClick={openGoogleMaps}
            >
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2092.526424917947!2d15.103199315770069!3d58.33375998033615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46596f9b5f5f5f5f%3A0x5f5f5f5f5f5f5f5f!2sHulje%20%C3%96sterg%C3%A5rd%201%2C%20595%2041%20Mj%C3%B6lby!5e0!3m2!1sen!2sse!4v1621234567890!5m2!1sen!2sse&maptype=satellite"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen={true}
                    loading="lazy"
                    title="Mjölby Moské Location"
                ></iframe>
            </div>
            <p className="text-sm mt-4">
                Klicka på kartan för att öppna i Google Maps
            </p>
        </div>
    );
};

export default MosqueMap;
