import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import { Mail, Lock } from 'lucide-react';
import logo from '/logo-mjolby-moske.png';

const AdminLoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { signIn } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    try {
      await signIn(email, password);
      navigate('/admin-dashboard');  // Redirect to admin dashboard
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-primary via-secondary to-primary-light p-4">
      <div className="bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg p-8 rounded-2xl shadow-2xl w-full max-w-md">
        <div className="flex flex-col items-center mb-6">
          <img
            src={logo}
            alt="Mjölby Moské Logo"
            className="w-24 h-24 mb-4 rounded-md"
          />
          <h2 className="text-3xl font-bold text-primary">Admin Login</h2>
        </div>
        {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
        <form onSubmit={handleLogin} className="space-y-6">
          <div className="relative">
            <label htmlFor="email" className="block text-gray-700 mb-1 font-medium">Email</label>
            <div className="flex items-center">
              <span className="absolute pl-3 text-gray-400">
                <Mail size={20} />
              </span>
              <input
                type="email"
                id="email"
                placeholder="admin@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full pl-10 pr-4 py-2 rounded-md bg-gray-100 text-gray-800 focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
          </div>
          <div className="relative">
            <label htmlFor="password" className="block text-gray-700 mb-1 font-medium">Password</label>
            <div className="flex items-center">
              <span className="absolute pl-3 text-gray-400">
                <Lock size={20} />
              </span>
              <input
                type="password"
                id="password"
                placeholder="********"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full pl-10 pr-4 py-2 rounded-md bg-gray-100 text-gray-800 focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-primary text-white font-semibold py-3 rounded-md hover:bg-primary-dark transition-colors"
          >
            Login
          </button>
        </form>
        <p className="text-center text-gray-600 mt-6 text-sm">
          &copy; {new Date().getFullYear()} Mjölby Moské
        </p>
      </div>
    </div>
  );
};

export default AdminLoginPage;
