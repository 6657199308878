import React, { useState, useEffect } from "react";
import Calendar from "../components/Calendar";
import { PrayerTimesData } from "../utils/prayerTimeUtils";

interface CalendarPageProps {
    prayerTimesData: PrayerTimesData;
    onDateSelect: (date: Date) => void;
}

const CalendarPage: React.FC<CalendarPageProps> = ({ prayerTimesData, onDateSelect }) => {
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [animate, setAnimate] = useState(false);
    const [selectedPrayerTimes, setSelectedPrayerTimes] = useState<PrayerTimesData[string] | null>(null);

    const handleDateSelection = (date: Date) => {
        setSelectedDate(date);
        setAnimate(true);
        onDateSelect(date);
    };

    useEffect(() => {
        const selectedDateString = selectedDate.toISOString().split("T")[0];
        const times = prayerTimesData[selectedDateString];
        setSelectedPrayerTimes(times || null);

        if (animate) {
            const timer = setTimeout(() => setAnimate(false), 300);
            return () => clearTimeout(timer);
        }
    }, [selectedDate, prayerTimesData, animate]);

    const formatTime = (time: string): string => {
        return time.slice(0, 5);
    };

    return (
        <div className="max-w-lg mx-auto w-full">
            <h1 className="text-2xl font-bold mb-2">Bönetider Kalender</h1>
            <p className="text-lg mb-4">Välj ett datum för att se bönetider</p>
            <Calendar
                selectedDate={selectedDate}
                onDateSelect={handleDateSelection}
            />
            {selectedPrayerTimes ? (
                <div
                    className={`mt-4 bg-secondary text-white p-4 rounded-lg transition-all duration-300 
                        ${animate ? 'scale-105 opacity-70' : 'scale-100 opacity-100'}`}
                >
                    <h2 className="text-xl font-bold mb-4 pb-2 border-b border-white text-center">
                        Bönetider för{" "}
                        {selectedDate.toLocaleDateString("sv-SE", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        })}
                    </h2>
                    <div className="grid grid-cols-3 gap-4 pt-2">
                        {selectedPrayerTimes && Object.entries(selectedPrayerTimes).map(
                            ([prayer, time]) => (
                                <div key={prayer} className="text-center">
                                    <div className="font-bold">{prayer}</div>
                                    <div>{formatTime(time)}</div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            ) : (
                <div className="mt-4 bg-secondary text-white p-4 rounded-lg">
                    Inga bönetider tillgängliga för valt datum.
                </div>
            )}
        </div>
    );
};

export default CalendarPage;