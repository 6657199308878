import { useState } from "react";
import { motion } from "framer-motion";
import { Smartphone, AlertCircle } from "lucide-react";

export default function AppDownloadPage() {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className="w-full max-w-4xl mx-auto">
            <motion.h1
                className="text-3xl sm:text-5xl font-extrabold mb-4 text-center"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                Hämta vår app
            </motion.h1>

            <div className="bg-secondary shadow-xl rounded-3xl overflow-hidden">
                <div className="p-8 md:p-12">
                    <div className="flex flex-col md:flex-row gap-12 items-center">
                        <div className="flex-1 space-y-8">
                            <motion.div
                                className="flex items-center space-x-4"
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                            >
                                <img
                                    src="/logo-pray-on-time.png"
                                    alt="Pray on Time Logo"
                                    className="w-16 h-16 rounded-xl shadow-lg"
                                />
                                <p className="text-2xl font-semibold">
                                    Pray on Time
                                </p>
                            </motion.div>

                            <motion.p
                                className="text-xl"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.4 }}
                            >
                                Ladda ner vår Android-app för att enkelt
                                hålla koll på bönetider!
                            </motion.p>

                            <motion.div
                                className="flex items-center space-x-2 text-yellow-300"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                            >
                                <AlertCircle className="w-5 h-5" />
                                <p className="text-sm">
                                    iOS-version kommer snart!
                                </p>
                            </motion.div>

                            <motion.div
                                className="flex flex-col sm:flex-row items-center space-y-6 sm:space-y-0 sm:space-x-8"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: 0.6 }}
                            >
                                <img
                                    src="/pray-on-time-qr-code.png"
                                    alt="QR Code"
                                    className="w-32 h-32 bg-white p-2 rounded-lg shadow-lg"
                                />
                                <div className="space-y-4">
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.osmandev.pray_on_time"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-secondary bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary transition-colors duration-300"
                                    >
                                        <Smartphone className="mr-2 h-5 w-5" />
                                        Ladda ner Android-appen
                                    </a>
                                    <p className="text-sm text-center sm:text-left">
                                        Skanna QR-koden eller klicka på
                                        knappen ovan
                                    </p>
                                </div>
                            </motion.div>
                        </div>
                        <motion.div
                            className="flex-1 relative max-w-[300px] w-full"
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5, delay: 0.8 }}
                            onHoverStart={() => setIsHovered(true)}
                            onHoverEnd={() => setIsHovered(false)}
                        >
                            <img
                                src="/pray-on-time-screenshot.png"
                                alt="App Screenshot"
                                className="w-full h-auto rounded-3xl shadow-2xl transition-transform duration-300 ease-in-out"
                                style={{
                                    transform: isHovered
                                        ? "scale(1.05) rotate(-5deg)"
                                        : "scale(1) rotate(0)",
                                }}
                            />
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
}
