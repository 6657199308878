import React, { useState, useEffect, useCallback, useRef } from "react";
import DateSelector from "./components/DateSelector";
import NextPrayer from "./components/NextPrayer";
import PrayerTimeGrid from "./components/PrayerTimeGrid";
import Header from "./components/Header";
import SlideMenu from "./components/SlideMenu";
import ContactInfo from "./components/ContactInfo";
import InfoPage from "./pages/InfoPage";
import CalendarPage from "./pages/CalendarPage";
import AppDownloadPage from "./pages/AppDownloadPage";
import WeeklyPrayerTimes from "./components/WeeklyPrayerTimes";

import {
  PrayerTimesData,
  getNextPrayer,
  getCurrentPrayer,
} from "./utils/prayerTimeUtils";

import { fetchPrayerTimes, fetchWeeklyPrayerTimes } from "./utils/api";
import { PrayerTime, WeeklyPrayerTime } from "./utils/types";

import { format, startOfMonth, endOfMonth, getMonth, getYear } from "date-fns";
import {
  getCachedPrayerTimes,
  setCachedPrayerTimes,
  clearCachedPrayerTimes,
} from "./utils/cache";

// Function to check if a given date is in the current month
const isCurrentMonth = (date: Date): boolean => {
  const now = new Date();
  return getMonth(date) === getMonth(now) && getYear(date) === getYear(now);
};

const PrayerTimesApp: React.FC = () => {
  // Existing state variables
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [currentPrayer, setCurrentPrayer] = useState<string | null>(null);
  const [prayerTimesData, setPrayerTimesData] = useState<PrayerTimesData>({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // New state for weekly prayer times
  const [weeklyPrayerTimes, setWeeklyPrayerTimes] = useState<WeeklyPrayerTime | null>(null);
  const [isWeeklyLoading, setIsWeeklyLoading] = useState<boolean>(true);

  // New state to track current month and year
  const [currentMonthYear, setCurrentMonthYear] = useState<{ month: number; year: number }>({
    month: getMonth(new Date()),
    year: getYear(new Date()),
  });

  // In-memory cache for non-current months
  const inMemoryCache = useRef<Map<string, PrayerTimesData>>(new Map());

  // Function to load prayer times
  const loadPrayerTimes = useCallback(async (date: Date) => {
    setIsLoading(true);
    try {
      const year = getYear(date);
      const month = getMonth(date); // 0-based
      const key = `${year}-${month}`; // Unique key for each month

      if (isCurrentMonth(date)) {
        // For current month, check localStorage
        const cachedData = getCachedPrayerTimes(year, month);
        if (cachedData) {
          console.log(`[Cache] Retrieved data from cache for ${year}-${month + 1}`, cachedData);
          setPrayerTimesData(cachedData);
        } else {
          // Fetch from API and cache in localStorage
          console.log(`[App] Fetching prayer times from API for ${year}-${month + 1}`);
          const startDate = format(startOfMonth(date), "yyyy-MM-dd");
          const endDate = format(endOfMonth(date), "yyyy-MM-dd");
          const prayerTimes = await fetchPrayerTimes(startDate, endDate);

          const formattedData: PrayerTimesData = {};
          prayerTimes.forEach((pt: PrayerTime) => {
            formattedData[pt.date] = {
              Fajr: pt.fajr,
              Sunrise: pt.sunrise,
              Dhuhr: pt.dhuhr,
              Asr: pt.asr,
              Maghrib: pt.maghrib,
              Isha: pt.isha,
            };
          });

          setPrayerTimesData(formattedData);
          setCachedPrayerTimes(year, month, formattedData); // Cache in localStorage
        }
      } else {
        // For other months, check in-memory cache
        if (inMemoryCache.current.has(key)) {
          console.log(`[Cache] Retrieved data from in-memory cache for ${year}-${month + 1}`, inMemoryCache.current.get(key));
          setPrayerTimesData(inMemoryCache.current.get(key)!);
        } else {
          // Fetch from API and store in in-memory cache
          console.log(`[App] Fetching prayer times from API for ${year}-${month + 1}`);
          const startDate = format(startOfMonth(date), "yyyy-MM-dd");
          const endDate = format(endOfMonth(date), "yyyy-MM-dd");
          const prayerTimes = await fetchPrayerTimes(startDate, endDate);

          const formattedData: PrayerTimesData = {};
          prayerTimes.forEach((pt: PrayerTime) => {
            formattedData[pt.date] = {
              Fajr: pt.fajr,
              Sunrise: pt.sunrise,
              Dhuhr: pt.dhuhr,
              Asr: pt.asr,
              Maghrib: pt.maghrib,
              Isha: pt.isha,
            };
          });

          setPrayerTimesData(formattedData);
          inMemoryCache.current.set(key, formattedData); // Cache in-memory
        }
      }
    } catch (error) {
      console.error("Error loading prayer times:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Function to load weekly prayer times
  const loadWeeklyPrayerTimes = useCallback(async () => {
    setIsWeeklyLoading(true);
    try {
      const data = await fetchWeeklyPrayerTimes();
      setWeeklyPrayerTimes(data);
    } catch (error) {
      console.error("Error fetching weekly prayer times:", error);
      setWeeklyPrayerTimes(null);
    } finally {
      setIsWeeklyLoading(false);
    }
  }, []);

  // Initial load for the current month and weekly prayer times
  useEffect(() => {
    const today = new Date();
    loadPrayerTimes(today);
    loadWeeklyPrayerTimes();
  }, [loadPrayerTimes, loadWeeklyPrayerTimes]);

  // Update current time every second
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      setCurrentTime(now);
      if (isCurrentMonth(selectedDate)) {
        setCurrentPrayer(
          getCurrentPrayer(selectedDate, now, prayerTimesData)
        );
      } else {
        setCurrentPrayer(null);
      }

      // Check if month or year has changed
      const currentMonth = getMonth(now);
      const currentYear = getYear(now);
      if (
        currentMonth !== currentMonthYear.month ||
        currentYear !== currentMonthYear.year
      ) {
        setCurrentMonthYear({ month: currentMonth, year: currentYear });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [selectedDate, prayerTimesData, currentMonthYear]);

  // useEffect to handle cache retrieval based on month/year changes
  useEffect(() => {
    const { month, year } = currentMonthYear;
    if (isCurrentMonth(new Date())) {
      const cachedData = getCachedPrayerTimes(year, month);
      if (!cachedData) {
        console.log(
          `[App] Current month's cache is missing or expired. Refreshing cache.`
        );
        loadPrayerTimes(new Date());
      }
      // Else, cache is valid and already loaded
    } else {
      // If it's a new month, clear the current cache and fetch new data
      console.log(
        `[App] New month detected. Clearing previous cache and loading new month's prayer times.`
      );
      clearCachedPrayerTimes();
      inMemoryCache.current.clear();
      loadPrayerTimes(new Date());
    }
  }, [currentMonthYear, loadPrayerTimes]);

  // Handle date selection
  const handleDateSelection = (date: Date): void => {
    setSelectedDate(date);
    loadPrayerTimes(date);
  };

  const nextPrayer = getNextPrayer(
    selectedDate,
    currentTime,
    prayerTimesData
  );
  const isToday = selectedDate.toDateString() === new Date().toDateString();

  const mainPageContent = isLoading ? (
    <div className="text-center text-orange-600">Loading prayer times...</div>
  ) : Object.keys(prayerTimesData).length === 0 ? (
    <div className="text-center text-orange-600">Inga bönetider tillgängliga.</div>
  ) : (
    <>
      {/* Responsive Flex Layout */}
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Left Column: DateSelector, NextPrayer, PrayerTimeGrid */}
        <div className="flex flex-col space-y-6 flex-1">
          <DateSelector
            selectedDate={selectedDate}
            onDateSelect={handleDateSelection}
          />
          <NextPrayer
            nextPrayer={nextPrayer}
            isToday={isToday}
            isLoading={isLoading}
            selectedDate={selectedDate}
          />
          <PrayerTimeGrid
            prayerTimes={
              prayerTimesData[selectedDate.toISOString().split("T")[0]]
            }
            isLoading={isLoading}
            currentPrayer={currentPrayer}
            selectedDate={selectedDate}
          />
        </div>
        {/* Right Column: WeeklyPrayerTimes */}
        <div className="flex-1">
          <WeeklyPrayerTimes
            weeklyPrayerTimes={weeklyPrayerTimes}
            isLoading={isWeeklyLoading}
          />
        </div>
      </div>
    </>
  );

  return (
    <div
      className={`bg-primary min-h-screen text-white font-arial-rounded flex flex-col relative ${
        isMenuOpen ? "overflow-hidden" : ""
      }`}
    >
      <Header onMenuToggle={() => setIsMenuOpen(!isMenuOpen)} />
      <div
        className={`flex-grow w-full max-w-7xl mx-auto p-4 transition-all duration-300 ${
          isMenuOpen ? "blur-sm" : ""
        }`}
      >
        <div className="max-w-7xl mx-auto lg:max-w-full lg:w-full mt-20">
          {/* Since routes are moved, use conditional rendering */}
          {window.location.pathname === '/kalender' ? (
            <CalendarPage
              prayerTimesData={prayerTimesData}
              onDateSelect={handleDateSelection}
            />
          ) : window.location.pathname === '/info' ? (
            <InfoPage />
          ) : window.location.pathname === '/app' ? (
            <AppDownloadPage />
          ) : (
            mainPageContent
          )}
        </div>
      </div>
      <ContactInfo />
      <SlideMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
    </div>
  );
};

export default PrayerTimesApp;