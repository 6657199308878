import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'lucide-react';

interface HeaderProps {
  onMenuToggle: () => void;
}

const Header: React.FC<HeaderProps> = ({ onMenuToggle }) => {
  return (
    <header className="fixed top-0 left-0 right-0 bg-secondary p-3 z-50 shadow-md h-16">
      <div className="flex flex-row justify-between items-center max-w-full mx-auto h-full px-4 lg:px-8">
        <Link to="/" className="flex items-center">
          <img 
            src="/logo-mjolby-moske.png" 
            alt="Mjölby Moské Logo" 
            className="h-10 w-auto mr-2 shadow-lg rounded-xl filter drop-shadow-[0_0_8px_rgba(255,255,255,0.5)]"
          />
          <div className="text-2xl sm:text-3xl font-arial-rounded-bold text-white">
            Mjölby Moské
          </div>
        </Link>
        <button 
          onClick={onMenuToggle}
          className="text-white focus:outline-none"
        >
          <Menu size={24} />
        </button>
      </div>
    </header>
  );
};

export default Header;