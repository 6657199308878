import React from 'react';

const ContactInfo: React.FC = () => {
  return (
    <footer className="bg-secondary p-4 mt-auto">
      <div className="max-w-lg mx-auto">
        <h2 className="text-xl font-arial-rounded-bold mb-2">Kontakta oss</h2>
        <p className="font-arial-rounded">Email: mjolbymoske@gmail.com</p>
        <p className="font-arial-rounded">Address: Hulje Östergård 1, Mjölby</p>
      </div>
    </footer>
  );
};

export default ContactInfo;