import React, { useEffect, useState } from 'react';
import { PrayerTimes } from '../utils/prayerTimeUtils';

interface PrayerTimeGridProps {
  prayerTimes: PrayerTimes | undefined;
  isLoading: boolean;
  currentPrayer: string | null;
  selectedDate: Date;
}

const formatTime = (time: string): string => {
  return time.slice(0, 5);
};

const PrayerTimeGrid: React.FC<PrayerTimeGridProps> = ({ prayerTimes, isLoading, currentPrayer, selectedDate }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
    const timer = setTimeout(() => setAnimate(false), 300);
    return () => clearTimeout(timer);
  }, [selectedDate]);

  if (!prayerTimes) {
    return <div>Inga bönetider tillgängliga för detta datum.</div>;
  }

  const translatePrayer = (prayer: string): string => {
    const translations: { [key: string]: string } = {
      'Fajr': 'Fajr',
      'Sunrise': 'Soluppgång',
      'Dhuhr': 'Dhuhr',
      'Asr': 'Asr',
      'Maghrib': 'Maghrib',
      'Isha': 'Isha'
    };
    return translations[prayer] || prayer;
  };
  
  const isToday = selectedDate.toDateString() === new Date().toDateString();

  return (
    <div className={`grid grid-cols-2 gap-4 mb-4 sm:mb-6 transition-all duration-300 ${isLoading ? 'opacity-50' : 'opacity-100'}`}>
      {Object.entries(prayerTimes).map(([prayer, time]) => (
        <div 
          key={prayer} 
          className={`bg-secondary rounded-lg p-4 
            ${isToday && currentPrayer === prayer && prayer !== 'Sunrise' ? 'ring-2 ring-white' : ''} 
            ${animate ? 'scale-105 opacity-70' : 'scale-100 opacity-100'} 
            transition-all duration-300`}
        >
          <div className="text-lg sm:text-xl font-arial-rounded">{translatePrayer(prayer)}</div>
          <div className="text-2xl sm:text-3xl font-arial-rounded-bold">{formatTime(time)}</div>
        </div>
      ))}
    </div>
  );
};

export default PrayerTimeGrid;