import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { X, Home, Calendar, Info, Smartphone } from 'lucide-react';

interface SlideMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const SlideMenu: React.FC<SlideMenuProps> = ({ isOpen, onClose }) => {
  const location = useLocation();

  const menuItems = [
    { path: '/', label: 'Hem', icon: Home },
    { path: '/kalender', label: 'Kalender', icon: Calendar },
    { path: '/info', label: 'Info', icon: Info },
    { path: '/app', label: 'Hämta vår app', icon: Smartphone },
  ];

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50" onClick={onClose}></div>
      )}
      <div 
        className={`fixed top-0 right-0 h-full w-64 bg-white text-primary shadow-lg transform transition-transform duration-300 ease-in-out z-50 ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="flex justify-between items-center p-4 bg-primary text-white">
          <h2 className="text-xl font-bold">Meny</h2>
          <button onClick={onClose} className="text-white focus:outline-none">
            <X size={24} />
          </button>
        </div>
        <nav className="mt-4 px-3">
          {menuItems.map((item) => {
            const Icon = item.icon;
            return (
              <Link
                key={item.path}
                to={item.path}
                className={`flex items-center px-3 py-2 my-1 text-lg rounded-md transition-all duration-200 ${
                  location.pathname === item.path
                    ? 'bg-primary text-white shadow-md'
                    : 'text-primary hover:bg-primary hover:text-white'
                }`}
                onClick={onClose}
              >
                <Icon size={20} className="mr-3" />
                {item.label}
              </Link>
            );
          })}
        </nav>
      </div>
    </>
  );
};

export default SlideMenu;