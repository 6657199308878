import React, { useState } from "react";

interface CalendarProps {
    selectedDate: Date;
    onDateSelect: (date: Date) => void;
}

const Calendar: React.FC<CalendarProps> = ({ selectedDate, onDateSelect }) => {
    const [currentMonth, setCurrentMonth] = useState(selectedDate);

    const daysInMonth = new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth() + 1,
        0
    ).getDate();
    const firstDayOfMonth = new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth(),
        1
    ).getDay();
    const startingDay = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

    const monthNames = [
        "januari",
        "februari",
        "mars",
        "april",
        "maj",
        "juni",
        "juli",
        "augusti",
        "september",
        "oktober",
        "november",
        "december",
    ];
    const dayNames = ["mån", "tis", "ons", "tors", "fre", "lör", "sön"];

    const changeMonth = (offset: number) => {
        const newMonth = new Date(
            currentMonth.getFullYear(),
            currentMonth.getMonth() + offset,
            1
        );
        setCurrentMonth(newMonth);
    };

    const renderCalendar = () => {
        const days = [];
        for (let i = 0; i < startingDay; i++) {
            days.push(
                <div key={`empty-${i}`} className="text-center p-2"></div>
            );
        }
        for (let day = 1; day <= daysInMonth; day++) {
            const date = new Date(
                currentMonth.getFullYear(),
                currentMonth.getMonth(),
                day
            );
            const isSelected =
                date.toDateString() === selectedDate.toDateString();
            const isToday = date.toDateString() === new Date().toDateString();
            days.push(
                <div
                    key={day}
                    className={`text-center p-2 cursor-pointer ${
                        isSelected
                            ? "bg-white text-black rounded-full"
                            : isToday
                            ? "border border-white rounded-full"
                            : ""
                    }`}
                    onClick={() => {
                        const selectedDate = new Date(
                            Date.UTC(
                                currentMonth.getFullYear(),
                                currentMonth.getMonth(),
                                day
                            )
                        );
                        onDateSelect(selectedDate);
                    }}
                >
                    {day}
                </div>
            );
        }
        return days;
    };

    return (
        <div className="bg-secondary text-white p-4 rounded-lg">
            <div className="flex justify-between items-center mb-4">
                <button onClick={() => changeMonth(-1)} className="text-white">
                    &lt;
                </button>
                <h2 className="text-xl font-bold">{`${
                    monthNames[currentMonth.getMonth()]
                } ${currentMonth.getFullYear()}`}</h2>
                <button onClick={() => changeMonth(1)} className="text-white">
                    &gt;
                </button>
            </div>
            <div className="grid grid-cols-7 gap-1">
                {dayNames.map((day) => (
                    <div key={day} className="text-center font-bold">
                        {day}
                    </div>
                ))}
                {renderCalendar()}
            </div>
        </div>
    );
};

export default Calendar;
