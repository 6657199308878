import React from 'react';
import { WeeklyPrayerTime } from '../utils/types';
import { format } from 'date-fns';
import { FiInfo } from 'react-icons/fi';

interface WeeklyPrayerTimesProps {
  weeklyPrayerTimes: WeeklyPrayerTime | null;
  isLoading: boolean;
}

const WeeklyPrayerTimes: React.FC<WeeklyPrayerTimesProps> = ({ weeklyPrayerTimes, isLoading }) => {
  if (isLoading) {
    return <div className="text-center text-blue-600 text-lg">Laddar veckans bönetider...</div>;
  }

  if (!weeklyPrayerTimes) {
    return <div className="text-center text-blue-600 text-lg">Inga bönetider tillgängliga för denna vecka.</div>;
  }

  const { week_start, week_end, fajr, dhuhr, asr, maghrib, isha, djuma_khutba, djuma_prayer } = weeklyPrayerTimes;

  const formatDateRange = (start: string, end: string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return `${format(startDate, 'd MMMM')} - ${format(endDate, 'd MMMM')}`;
  };

  return (
    <div className="bg-blue-100 text-secondary rounded-lg p-6 mb-6 shadow-md">
      <h3 className="text-2xl font-bold mb-4">Församlingsböner i Moskén</h3>
      <h4 className="text-xl font-semibold mb-4">{formatDateRange(week_start, week_end)}</h4>
      <ul className="space-y-3 text-lg">
        <li><span className="font-semibold">Fajr:</span> {fajr}</li>
        <li><span className="font-semibold">Dhuhr:</span> {dhuhr}</li>
        <li><span className="font-semibold">Asr:</span> {asr}</li>
        <li><span className="font-semibold">Maghrib:</span> {maghrib}</li>
        <li><span className="font-semibold">Isha:</span> {isha}</li>
        <li><span className="font-semibold">Djuma:</span> {djuma_khutba} (Khutba) och {djuma_prayer} (Djuma bönen)</li>
      </ul>
      <div className="flex items-start rounded-md p-4 mt-6 shadow-inner">
        <FiInfo className="w-6 h-6 mr-3 mt-1" />
        <div className="space-y-4">
          <p>Församlingsböner är gemensamma böner som hålls i moskén.</p>
          <p>"+5 min" betyder att bönen startar 5 minuter efter bönetiden (athan-tiden).</p>
        </div>
      </div>
    </div>
  );
};

export default WeeklyPrayerTimes;