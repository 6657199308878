import React, { useEffect, useState } from 'react';
import { supabase } from '../utils/supabase';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import logo from '/logo-mjolby-moske.png';

interface WeeklyPrayerTimes {
  id: number; // Fixed ID
  week_start: string;
  week_end: string;
  fajr: string;
  dhuhr: string;
  asr: string;
  maghrib: string;
  isha: string;
  djuma_khutba: string;
  djuma_prayer: string;
}

const AdminDashboard: React.FC = () => {
  const [prayerTimes, setPrayerTimes] = useState<WeeklyPrayerTimes>({
    id: 1, // Fixed ID
    week_start: '',
    week_end: '',
    fajr: '',
    dhuhr: '',
    asr: '',
    maghrib: '',
    isha: '',
    djuma_khutba: '',
    djuma_prayer: ''
  });

  // New state to store the initial data
  const [initialPrayerTimes, setInitialPrayerTimes] = useState<WeeklyPrayerTimes | null>(null);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { signOut, session } = useAuth();
  const navigate = useNavigate();

  // Validation function
  const validateInput = (id: string, value: string) => {
    const absoluteTimePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
    const relativeTimePattern = /^\+\d+\s?min$/;

    if (!absoluteTimePattern.test(value) && !relativeTimePattern.test(value)) {
      setErrors(prev => ({ ...prev, [id]: 'Ogiltigt format. Använd HH:MM eller +X min.' }));
    } else {
      setErrors(prev => ({ ...prev, [id]: '' }));
    }
  };

  // Handle input changes with validation
  const handleChange = (id: string, value: string) => {
    setPrayerTimes(prev => ({ ...prev, [id]: value }));
    validateInput(id, value);
  };

  // Helper function to compare two prayer times objects
  const isPrayerTimesChanged = (current: WeeklyPrayerTimes, initial: WeeklyPrayerTimes): boolean => {
    return Object.keys(current).some(key => current[key as keyof WeeklyPrayerTimes] !== initial[key as keyof WeeklyPrayerTimes]);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Check if any field has been changed
    if (initialPrayerTimes && !isPrayerTimesChanged(prayerTimes, initialPrayerTimes)) {
      setError('Inga ändringar har gjorts.');
      return;
    }

    // Check for validation errors
    const hasErrors = Object.values(errors).some(errMsg => errMsg !== '');
    if (hasErrors) {
      setError('Vänligen rätta till felen innan du skickar.');
      return;
    }

    try {
      const { error } = await supabase
        .from('weekly_prayer_times')
        .upsert([prayerTimes], { onConflict: 'id' });
      if (error) throw error;
      setSuccess('Bönetider uppdaterade framgångsrikt!');

      // Update initialPrayerTimes to the latest data after successful update
      setInitialPrayerTimes(prayerTimes);
    } catch (err: any) {
      setError(err.message);
    }
  };

  useEffect(() => {
    const fetchCurrentPrayerTimes = async () => {
      if (!session) return;
      try {
        const { data, error } = await supabase
          .from('weekly_prayer_times')
          .select('*')
          .eq('id', 1)
          .single();

        if (error && error.code !== 'PGRST116') { // Ignore not found
          throw error;
        }

        if (data) {
          const fetchedPrayerTimes: WeeklyPrayerTimes = {
            id: 1,
            week_start: data.week_start,
            week_end: data.week_end,
            fajr: data.fajr,
            dhuhr: data.dhuhr,
            asr: data.asr,
            maghrib: data.maghrib,
            isha: data.isha,
            djuma_khutba: data.djuma_khutba,
            djuma_prayer: data.djuma_prayer
          };
          setPrayerTimes(fetchedPrayerTimes);
          setInitialPrayerTimes(fetchedPrayerTimes); // Set initial data
        } else {
          // If no data, insert the initial row with id=1
          const { error: insertError } = await supabase
            .from('weekly_prayer_times')
            .insert([prayerTimes]);
          if (insertError) throw insertError;
          setInitialPrayerTimes(prayerTimes); // Set initial data after insert
        }
      } catch (err: any) {
        setError(err.message);
      }
    };
    fetchCurrentPrayerTimes();
  }, [session]);

  const handleSignOut = async () => {
    await signOut();
    navigate('/admin-login');
  };

  return (
    <div className="min-h-screen bg-primary flex flex-col items-center p-4">
      <div className="w-full max-w-4xl bg-blue-100 p-6 sm:p-8 rounded-2xl shadow-xl">
       {/* Header Section with Logo */}
       <div className="flex items-center justify-between mb-8">
          <div className="flex items-center space-x-4">
            <img
              src={logo}
              alt="Mjölby Moské Logo"
              className="w-12 h-12 sm:w-16 sm:h-16 rounded-md "
            />
            <h2 className="text-2xl sm:text-3xl font-bold text-primary">
              Admin
            </h2>
          </div>
          <button
            onClick={handleSignOut}
            className="bg-red-500 text-white text-sm sm:text-base px-4 py-2 rounded-md hover:bg-red-600 transition-colors"
          >
            Logga ut
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Week Dates Section */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div>
              <label htmlFor="week_start" className="block text-gray-700 mb-1 font-medium">Start</label>
              <input
                type="date"
                id="week_start"
                value={prayerTimes.week_start}
                onChange={(e) => setPrayerTimes({ ...prayerTimes, week_start: e.target.value })}
                required
                className="w-full px-4 py-2 rounded-md bg-gray-50 text-gray-800 focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
            <div>
              <label htmlFor="week_end" className="block text-gray-700 mb-1 font-medium">Slut</label>
              <input
                type="date"
                id="week_end"
                value={prayerTimes.week_end}
                onChange={(e) => setPrayerTimes({ ...prayerTimes, week_end: e.target.value })}
                required
                className="w-full px-4 py-2 rounded-md bg-gray-50 text-gray-800 focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
          </div>

          {/* Prayer Times Section */}
          <div>
            <h3 className="text-2xl font-semibold text-primary mb-4">Bönetider</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {/* Fajr */}
              <div>
                <label htmlFor="fajr" className="block text-gray-700 mb-1 font-medium">Fajr</label>
                <input
                  type="text"
                  id="fajr"
                  placeholder="HH:MM eller +X min"
                  value={prayerTimes.fajr}
                  onChange={(e) => handleChange('fajr', e.target.value)}
                  required
                  className={`w-full px-4 py-2 rounded-md bg-gray-50 text-gray-800 focus:outline-none focus:ring-2 focus:ring-primary ${errors.fajr ? 'border-red-500' : ''}`}
                />
                {errors.fajr && <p className="text-red-500 text-sm mt-1">{errors.fajr}</p>}
              </div>

              {/* Dhuhr */}
              <div>
                <label htmlFor="dhuhr" className="block text-gray-700 mb-1 font-medium">Dhuhr</label>
                <input
                  type="text"
                  id="dhuhr"
                  placeholder="HH:MM eller +X min"
                  value={prayerTimes.dhuhr}
                  onChange={(e) => handleChange('dhuhr', e.target.value)}
                  required
                  className={`w-full px-4 py-2 rounded-md bg-gray-50 text-gray-800 focus:outline-none focus:ring-2 focus:ring-primary ${errors.dhuhr ? 'border-red-500' : ''}`}
                />
                {errors.dhuhr && <p className="text-red-500 text-sm mt-1">{errors.dhuhr}</p>}
              </div>

              {/* Asr */}
              <div>
                <label htmlFor="asr" className="block text-gray-700 mb-1 font-medium">Asr</label>
                <input
                  type="text"
                  id="asr"
                  placeholder="HH:MM eller +X min"
                  value={prayerTimes.asr}
                  onChange={(e) => handleChange('asr', e.target.value)}
                  required
                  className={`w-full px-4 py-2 rounded-md bg-gray-50 text-gray-800 focus:outline-none focus:ring-2 focus:ring-primary ${errors.asr ? 'border-red-500' : ''}`}
                />
                {errors.asr && <p className="text-red-500 text-sm mt-1">{errors.asr}</p>}
              </div>

              {/* Maghrib */}
              <div>
                <label htmlFor="maghrib" className="block text-gray-700 mb-1 font-medium">Maghrib</label>
                <input
                  type="text"
                  id="maghrib"
                  placeholder="HH:MM eller +X min"
                  value={prayerTimes.maghrib}
                  onChange={(e) => handleChange('maghrib', e.target.value)}
                  required
                  className={`w-full px-4 py-2 rounded-md bg-gray-50 text-gray-800 focus:outline-none focus:ring-2 focus:ring-primary ${errors.maghrib ? 'border-red-500' : ''}`}
                />
                {errors.maghrib && <p className="text-red-500 text-sm mt-1">{errors.maghrib}</p>}
              </div>

              {/* Isha */}
              <div>
                <label htmlFor="isha" className="block text-gray-700 mb-1 font-medium">Isha</label>
                <input
                  type="text"
                  id="isha"
                  placeholder="HH:MM eller +X min"
                  value={prayerTimes.isha}
                  onChange={(e) => handleChange('isha', e.target.value)}
                  required
                  className={`w-full px-4 py-2 rounded-md bg-gray-50 text-gray-800 focus:outline-none focus:ring-2 focus:ring-primary ${errors.isha ? 'border-red-500' : ''}`}
                />
                {errors.isha && <p className="text-red-500 text-sm mt-1">{errors.isha}</p>}
              </div>
            </div>
          </div>

          <div>
            <h3 className="text-2xl font-semibold text-primary mb-4">Djuma</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {/* Djuma Khutba */}
              <div>
                <label htmlFor="djuma_khutba" className="block text-gray-700 mb-1 font-medium">Djuma Khutba</label>
                <input
                  type="text"
                  id="djuma_khutba"
                  placeholder="HH:MM eller +X min"
                  value={prayerTimes.djuma_khutba}
                  onChange={(e) => handleChange('djuma_khutba', e.target.value)}
                  required
                  className={`w-full px-4 py-2 rounded-md bg-gray-50 text-gray-800 focus:outline-none focus:ring-2 focus:ring-primary ${errors.djuma_khutba ? 'border-red-500' : ''}`}
                />
                {errors.djuma_khutba && <p className="text-red-500 text-sm mt-1">{errors.djuma_khutba}</p>}
              </div>

              {/* Djuma Prayer */}
              <div>
                <label htmlFor="djuma_prayer" className="block text-gray-700 mb-1 font-medium">Djuma Bön</label>
                <input
                  type="text"
                  id="djuma_prayer"
                  placeholder="HH:MM eller +X min"
                  value={prayerTimes.djuma_prayer}
                  onChange={(e) => handleChange('djuma_prayer', e.target.value)}
                  required
                  className={`w-full px-4 py-2 rounded-md bg-gray-50 text-gray-800 focus:outline-none focus:ring-2 focus:ring-primary ${errors.djuma_prayer ? 'border-red-500' : ''}`}
                />
                {errors.djuma_prayer && <p className="text-red-500 text-sm mt-1">{errors.djuma_prayer}</p>}
              </div>
            </div>
          </div>

        {/* Feedback Messages */}

        {error && (
          <p className="text-red-600 bg-red-100 p-3 rounded-md mb-6">
            {error}
          </p>
        )}
        {success && (
          <p className="text-green-600 bg-green-100 p-3 rounded-md mb-6">
            {success}
          </p>
        )}

          <div className="text-right">
            <button
              type="submit"
              className="bg-primary text-white font-semibold px-6 py-3 rounded-md hover:bg-primary-dark transition-colors"
            >
              Uppdatera Bönetider
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminDashboard;